import styled from "styled-components";
import bgEbook from "../../assets/images/bg-ebook.png";

export const ContainerHome = styled.div`
  min-height: 100vh;
`;

export const ContentHome = styled.div`
  position: relative;
  padding-top: 5rem;
  padding-bottom: 7rem;
  background-image: url("${bgEbook}");
  background-size: cover;
  background-position: bottom, center;
  background-repeat: no-repeat;

  .teacher-lays {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  @media only screen and (max-width: 576px) {
    padding-top: 3rem;
    padding-bottom: 0rem;

    .teacher-lays {
      position: initial;
      margin-bottom: -5px;
      display: flex;
      justify-content: center;
      width: 80%;
    }
  }

  @media (min-width: 577px) and (max-width: 768px) {
    padding-top: 3rem;
    padding-bottom: 0rem;

    .teacher-lays {
      position: initial;
      margin-bottom: -5px;
      display: flex;
      justify-content: center;
      width: 80%;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 899px) {
    padding-top: 3rem;
    padding-bottom: 0rem;

    .teacher-lays {
      position: initial;
      margin-bottom: -5px;
      display: flex;
      justify-content: center;
      width: 80%;
    }
  }
`;
