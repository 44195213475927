import React from "react";
import { ContentLeftSideHome, DownloadFree, Title } from "./style";

export default function LeftSideHome() {
  return (
    <ContentLeftSideHome>
      <Title>
        <h2>E-BOOK</h2>
        <h1 className="checklist yellow">CHECKLIST</h1>
        <h1>
          ENEM <span className="yellow">2023!</span>
        </h1>
      </Title>

      <p>
        <span className="yellow">Oi oi, gente!</span> Para você{" "}
        <span className="yellow">não perder seu tempo precioso</span>, criei
        este{" "}
        <span className="yellow">
          e-book com tudo que é obrigatório, opcional e proibido
        </span>{" "}
        levar no dia do Enem. Tem até dicas de snacks!
      </p>

      <p>
        Meu nome é Lays, eu{" "}
        <span className="yellow">
          ensino redação para o Enem há quase 6 anos,
        </span>{" "}
        por isso reuni todas essas informações para facilitar sua rotina.
      </p>

      <p>
        Agora sua tarefa será apenas focar no que é mais importante: revisar as
        matérias até o dia da prova.{" "}
        <span className="yellow">
          Depois de baixar, é só realizar o checklist.
        </span>
      </p>

      <DownloadFree>
        <h1>BAIXE</h1>
        <h1 className="free">GRÁTIS</h1>
      </DownloadFree>
    </ContentLeftSideHome>
  );
}
