import styled from "styled-components";

export const ContentRightSideHome = styled.div`
  width: 100%;
  height: 100%;
  margin-left: auto;

  form {
    padding: 4rem 2rem;
    background: #fbdd00;
    margin-top: 3rem;
  }
`;

export const Action = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  button {
    background-color: #2b00ca !important;
    color: white !important;
    min-width: 230px;
    padding: 0.5rem;
    font-size: 17px;
    border-radius: 12px;
`;

export const ValidateRecaptcha = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
`;

export const IconsBg = styled.div`
  display: flex;
  justify-content: center;
`;
