import styled from "styled-components";

export const ContentLeftSideHome = styled.div`
  color: white;

  p {
    font-size: 17px;
    line-height: 1.6;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 2rem;
  }

  .yellow {
    color: #fbdd00;
  }
`;

export const Title = styled.div`
  line-height: 1;
  font-style: italic;
  margin-bottom: 3rem;

  h2 {
    font-weight: 800;
    margin: 0;
    padding-left: 3rem;
  }

  h1 {
    font-weight: 800;
    margin: 0;
    font-size: 3em;
  }

  .checklist {
    padding-left: 0.5rem;
  }

  @media only screen and (max-width: 576px) {
    text-align: center;
    h2 {
      padding-left: 0;
    }

    .checklist {
      padding-left: 0;
    }
  }

  @media (min-width: 577px) and (max-width: 768px) {
    text-align: center;
    h2 {
      padding-left: 0;
    }

    .checklist {
      padding-left: 0;
    }
  }
`;

export const DownloadFree = styled.div`
  line-height: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 6rem;
  margin-bottom: 6rem;

  h1 {
    font-weight: 800;
    margin: 0;
    font-size: 3em;
    color: #fbdd00;
    font-style: italic;
  }

  .free {
    padding-left: 5rem;
  }

  @media only screen and (max-width: 576px) {
    margin-top: 3rem;
    margin-bottom: 3rem;
    .free {
      padding-left: 0;
    }
  }

  @media (min-width: 577px) and (max-width: 768px) {
    margin-top: 3rem;
    margin-bottom: 3rem;

    .free {
      padding-left: 0;
    }
  }

  @media (min-width: 900px) and (max-width: 1199px) {
    .free {
      padding-left: 0;
    }
  }
`;
