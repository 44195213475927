import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, CircularProgress, Grid } from "@mui/material";
import InputText from "../InputText";
import InputPhone from "../InputPhone";
import {
  Action,
  ContentRightSideHome,
  IconsBg,
  ValidateRecaptcha,
} from "./style";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import { isMobile } from "react-device-detect";
import api from "../../utils/api";
import icons from "../../assets/images/icons.png";

export default function RightSideHome() {
  const recaptchaRef = React.useRef(null);

  const [loading, setLoading] = useState(false);

  const {
    control,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "all",
  });

  const onSubmit = (data) => {
    const recaptchaValue = recaptchaRef.current.getValue();
    if (recaptchaValue) {
      subscribe(data);
    } else {
      isMobile
        ? alert("Por favor, preencha o recaptcha!")
        : toast.warn("Por favor, preencha o recaptcha!");
    }
  };

  const subscribe = async (data) => {
    setLoading(true);
    try {
      const result = await api.post("/ebook/baixar", data);
      reset();
      if (result?.data?.message) {
        isMobile
          ? alert(result.data.message)
          : toast.success(result.data.message);
      }
    } catch (error) {
      if (error?.response?.data?.error) {
        isMobile
          ? alert(error.response.data.error)
          : toast.error(error.response.data.error);
      } else {
        isMobile ? alert("Ocorreu um erro.") : toast.error("Ocorreu um erro.");
      }
    } finally {
      setLoading(false);
      recaptchaRef.current.reset();
    }
  };

  return (
    <ContentRightSideHome>
      <IconsBg>
        <img src={icons} alt="Ícones Background" />
      </IconsBg>

      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputText
              name="name"
              label="Nome"
              control={control}
              rules={{ required: true }}
              errors={errors?.name}
            />
          </Grid>
          <Grid item xs={12}>
            <InputPhone
              name="cellphone"
              label="WhatsApp"
              control={control}
              rules={{ required: true }}
              errors={errors?.cellphone}
            />
          </Grid>
          <Grid item xs={12}>
            <ValidateRecaptcha>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6LcMJnEUAAAAACJNP1KNKF64nJGtmeh_glhjvAEG"
              />
            </ValidateRecaptcha>
          </Grid>
          <Grid item xs={12}>
            <Action>
              <Button size="large" type="submit" disabled={loading}>
                {loading && (
                  <CircularProgress size={20} style={{ color: "white" }} />
                )}

                <strong>{!loading && "BAIXAR AGORA"}</strong>
              </Button>
            </Action>
          </Grid>
        </Grid>
      </form>
    </ContentRightSideHome>
  );
}
