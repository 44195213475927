import React from "react";
import { Container, Grid } from "@mui/material";
import { ContainerHome, ContentHome } from "./style";
import HeaderHome from "../../components/HeaderHome";
import LeftSideHome from "../../components/LeftSideHome";
import RightSideHome from "../../components/RightSideHome";
import FooterHome from "../../components/FooterHome";

import LaysImg from "../../assets/images/lays.png";

export default function Home() {
  return (
    <ContainerHome>
      <HeaderHome />

      <ContentHome>
        <Container fixed>
          <Grid container rowSpacing={0} columnSpacing={20}>
            <Grid item xs={12} sm={12} md={6}>
              <LeftSideHome />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <RightSideHome />
            </Grid>
          </Grid>
        </Container>

        <img className="teacher-lays" src={LaysImg} alt="Professora Lays" />
      </ContentHome>

      <FooterHome />
    </ContainerHome>
  );
}
